// Here you can add other styles

.validation-error{
  width: 100%;
  margin: 5px 0 15px;
  font-size: 13px;
  color: #f86c6b;
  text-align: left;
  padding-left: 12px;
}

.invalid-input{
  border: 1px solid #f86c6b ;
}

.valid-input{
  // border: 1px solid green;
}

.mr5{
  margin-right: 5px;
}

.admin-section{
  padding-right: 30px;
  .admin-name{
    margin-right: 10px;
  }
  .logout-icon{
    font-size: 18px;
    cursor: pointer;
    color: #fb256a;
  }
  
}
.ql-container{
  height: 200px !important;
}

.navbar-nav .open > .nav-link, .navbar-nav .open > .nav-link:hover, .navbar-nav .open > .nav-link:focus, .navbar-nav .active > .nav-link, .navbar-nav .active > .nav-link:hover, .navbar-nav .active > .nav-link:focus, .navbar-nav .nav-link.open, .navbar-nav .nav-link.open:hover, .navbar-nav .nav-link.open:focus, .navbar-nav .nav-link.active, .navbar-nav .nav-link.active:hover, .navbar-nav .nav-link.active:focus{
  color: #20a8d8 !important;
  pointer-events: none;
}

.loading-section{
  border:1px solid #ddd;
  padding: 20px;
  text-align: center;
}

.amenity-image-upload{
  position: relative;
  .image-loader{
    position: absolute;
    top: 30px;
    right: 0;
    font-size: 20px;
  }
}

.no-border{
  border: none;
}

.no-bookings{
  font-size: 14px;
    padding-left: 10px;
    font-weight: normal;
}

.react-datepicker-wrapper{
  width: 82%;
}

.location-suggestion-ul{
    padding: 0;
    border: 1px solid #ddd;
    list-style-type: none;
    position: absolute;
    z-index: 9;
    background: #fff;
    width: calc(100% - 30px);
    top:63px;
    li{
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #eee;
      i{
        margin-right: 5px;
      }
      &:hover{
        background: #efefef;
      }
    }
}

.update-profile-image{
  font-size: 14px;
    position: absolute;
    bottom: 0px;
    left: 70px;
    border: 2px solid #ddd;
    padding: 0px 4px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.uploading-image-loading{
  position: absolute;
  top: 28px;
  left: 43px;
}

.disbale-file-select{
  opacity: 0.5;
  pointer-events: none;
}

.uploading-id-loading{
  position: absolute;
  top: 31px;
  right: 20px;
  font-size: 20px;
}

// .caret{
//   display: inline-block;
//   width: 0;
//   height: 0;
//   margin-left: 2px;
//   vertical-align: middle;
//   border-top: 4px solid;
//   border-right: 4px solid transparent;
//   border-left: 4px solid transparent;
// }

// .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
//   content: "";
//   border-top: 0;
//   border-bottom: 4px solid;
// }

.user-profile-image{
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.cursor-pointer{
  cursor: pointer;
}

.list-loading{
  width: 650px;
  margin: 0 auto;
}

.space-rate{
  text-transform: capitalize;
  &:after{
    content: ' | '
  }
  &:last-child{
    &:after{
      content: ''
    }
  }
}

.amenity-title{
  text-transform: capitalize;
  &:after{
    content: ', '
  }
  &:last-child{
    &:after{
      content: ''
    }
  }
}

// .property-status{
//   .switch-label{
//     width: 65px;
//     .switch-input:checked ~ .switch-slider::before{
//       transform: translateX(29px);
//     }
//   }
// }

.active-status span, .active-status label{
  margin: 0 ;
}

.property-images{
  .image-upload{
    img{
      max-width: 100%;
      height: 100px;
    }
  }
}

.edit-property{
  .image-upload{
    img{
      max-width: 100%;
      height: 100px;
    }
  }
}

.capitalize{
  text-transform: capitalize;
}

.booking-start-date-filter{
  .react-datepicker-wrapper {
    float: left;
  }
  .input-group-append{
    height: 35px;
  }
}

.font14{
  font-size: 14px;
}

.hide-image{
  visibility: hidden;
}

.chat-botttom-margin{
 margin:0 0 10px;
}

.status-switch{
  width: 85px;
  .switch-input:checked ~ .switch-slider::before{
    transform: translateX(60px);
  }
  .switch-slider::after{
    right: 11px !important;
  }
  .switch-input:checked ~ .switch-slider::after {
    left: 7px !important;
  }
}
.details-status-switch{
  width: 100px;
  .switch-input:checked ~ .switch-slider::before{
    transform: translateX(75px);
  }
  .switch-slider::after{
    right: 11px !important;
  }
  .switch-input:checked ~ .switch-slider::after {
    left: 7px !important;
  }
}
.react-bs-table-no-data{
  display: table-cell !important;
}

.language-select{
  width: 150px;
  margin: 0 auto;
}

.uppercase {
  text-transform: uppercase;
}