// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

body {
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
}
button {
  padding-left: 20px;
  padding-right: 20px;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
}
.login-page {
  background-color: transparent;
  border: 0;
  text-align: center;
  .logo {
    margin: 0 0 20px;
    img {
      max-width: 42px;
    }
  }
  h2 {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.97px;
    text-align: center;
    color: #232735;
    text-transform: uppercase;
    margin-bottom: 60px;
  }
  .input-group {
    background: #fafafa;
    border-radius: 8px;
    font-size: 14px;
    color: #000 !important;
    padding: 0;
    height: 60px;
    line-height: 60px;
    vertical-align: top;
    position: relative;
    padding: 0;
    input {
      height: 60px;
      // border:0;
      background: #fafafa;
    }
  }
  .forgot-pass button {
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #445ee9;
  }
  .btn.btn-primary {
    background: #4583fe;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border: 0;
    margin: 35px auto;
    height: 44px;
    line-height: 44px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0;
    box-shadow: none;
    max-width: 70%;
    display: block;
    width: 100%;
  }
}
.table-panel {
  margin: 0 0 60px;
  .card,
  .card-body {
    padding: 0;
    border: 0;
  }
  .table-top {
    text-align: right;
    .input-group {
      max-width: 170px;
      display: inline-block;
      clear: none;
      margin-left: 15px;
      .form-control {
        width: 100%;
        font-size: 14px;
      }
    }
    .filter-icon {
      width: auto;
      color: #000;
      font-size: 20px;
    }
  }
  td:last-child {
    text-align: center;
  }
  .pagination {
    justify-content: flex-end;
  }
  .table td {
    font-size: 12px;
  }
  .table td:last-child {
    display: flex;
    line-height: 25px;
    font-size: 16px;
    border-top: 0;
    .switch-label {
      margin-right: 20px !important;
    }
  }
  .table thead th {
    font-size: 12px;
    text-transform: capitalize;
  }
}
.switch-outline-primary-alt .switch-input:checked + .switch-slider {
  color: #4583fe;
}
.page-item .page-link {
  color: #000;
}
img.navbar-brand-full {
  width: auto;
  max-width: 100%;
}
.facilities-table {
  max-width: 600px;
  margin: 60px auto;
  margin: 30px auto 0px;
}
.amenity-table {
  .table-top {
    margin-bottom: 20px;
  }
  th {
    min-width: 150px;
  }
  th:last-child {
    text-align: right;
  }
  td:last-child {
    justify-content: flex-end;
    button {
      margin-left: 20px;
    }
  }
}
.facility-modal {
  margin-top: 25%;
  .modal-content {
    max-width: 400px;
    margin: 0 auto;
    .modal-header {
      padding: 1rem 1rem;
      border-bottom: 1px solid #c8ced3;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .close {
        padding: 10px 14px 0 0;
        margin: -1rem -1rem -1rem auto;
        opacity: 1;
        font-size: 37px;
        color: #000;
      }
    }
    .modal-body {
      padding: 20px 1rem;
      .form-group {
        // margin-bottom: 0;
        .form-control {
          height: 40px;
        }
      }
    }
  }
}
.page-heading {
  display: block;
  text-align: center;
  margin: 50px 0 30px;
}
.static-content {
  margin: 50px auto;
  .row {
    margin: 15px 0 30px;
  }
  .col-12.col-sm-6 {
    margin: 0 auto;
    textarea {
      width: 100%;
      height: 90px;
      padding: 15px;
    }
  }
  .button-panel {
    text-align: center;
  }
}
.booking-panel {
  .col-12.col-sm-6 {
    margin: 0 auto;
    max-width: 60%;
    flex: 0 0 60%;
  }
  .card {
    border: 1px solid #c5cdd1;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    line-height: 34px;
    strong {
      font-size: 22px;
      span {
        font-weight: 500;
        font-size: 14px;
        margin-left: 20px;
        background: #fff;
        padding: 5px 10px;
        border-radius: 15px;
        color: #000;
        display: inline-block;
        line-height: 24px;
        em {
          color: #4cae4c;
          font-style: normal;
        }
        em:first-child {
          font-weight: 600;
          font-size: 24px;
          float: left;
          margin-right: 10px;
        }
      }
      span.dabited {
        em {
          color: #f04141;
        }
      }
    }
  }
  .card-body {
    padding: 1.25rem;
    .row {
      margin-bottom: 20px;
    }
    .app-switch-panel {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .submit-panel {
    text-align: center;
    button {
      min-width: 200px;
    }
  }
  .text-details {
    font-size: 18px;
    font-weight: 600;
    span {
      color: #20a8d8;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .view-text {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    img {
      max-width: 24px;
    }
  }
}
.select-panel {
  padding: 8px 0.75rem 0 !important;
  .form-group {
    margin-bottom: 0;
  }
}
.calender-section {
  position: relative;
  .input-group-append {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    border-radius: 0;
    span {
      border-radius: 0;
    }
  }
}
.edit-booking {
  .booking-seats {
    select {
      max-width: 70%;
      margin-right: 5%;
      float: left;
    }
    input {
      width: 25%;
      font-size: 12px;
    }
  }
}
.add-new-button {
  float: left;
  text-align: left;
  margin: 0 !important;
}
.seat-details {
  margin-bottom: 0 !important;
  .form-group {
    margin-bottom: 10px;
    label {
      margin-bottom: 0;
      line-height: 30px;
      font-weight: bold;
    }
  }
}
.image-upload {
  max-width: 23%;
  margin-right: 2%;
  float: left;
  position: relative;
  img {
    max-width: 100%;
    height: auto;
  }
  .button-delete {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
    cursor: pointer;
    -webkit-appearance: none;
    border: 0;
    background: #f04141;
    color: #fff;
    appearance: none;
    padding: 0 10px;
  }
}
.video-section {
  background-color: #f0f0f1;
  font-size: 20px;
  font-weight: 500;
  color: #9396a7;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  box-shadow: none;
  min-height: 150px;
  text-align: center;
  margin: 0 0 30px;
  overflow: hidden;
  max-height: 200px;
  img {
    max-width: 100%;
  }
}
.rate-details {
  font-size: 14px !important;
}

.create-new-password-bg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #999;
  padding: 9px 50px 12px;
  border-radius: 10px;
  -moz-box-shadow: 0px 0px 6px #666666;
  -webkit-box-shadow: 0px 0px 6px #666666;
  box-shadow: 0px 0px 6px #666666;
  background: #f4f4f4;
  h1 {
    font-size: 28px;
    font-family: Roboto;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
  }
  form input {
    display: block;
    margin: 0 auto 15px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 6px 10px;
    font-family: Roboto;
    color: #333;
  }
  form input:last-child {
    display: block;
    margin: 20px auto 15px;
    border: 1px solid #ccc;
    width: initial;
    border-radius: 3px;
    padding: 6px 15px;
    font-family: Roboto;
    background-color: #5cb85c;
    border-color: #4cae4c;
    color: #fff;
    font-size: 16px;
  }
}
.active-status {
  display: flex;
  flex-direction: row;
  line-height: 27px;
  span,
  label {
    margin: 0 10px 0 0 !important;
  }
}
.text-center {
  text-align: center !important;
}
.facilities-panel .form-check {
  width: 23%;
  margin-bottom: 10px;
}
.app-new-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
}
.favourite-panel {
  h2 {
    font-size: 20px;
    margin-bottom: 15px;
    span {
      color: #20a8d8;
      font-weight: 600;
    }
  }
  .table td:last-child {
    font-size: 12px;
    border-width: 0 1px 1px 0;
  }
}

.chat-section {
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  margin: 30px auto 0;
  height: calc(100vh - 146px);
  // max-width:80%;
  // border:1px solid #ccc;
  h2 {
    padding: 10px;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    text-align: center;
    background: transparent;
  }
}

.chat-right {
  // width: 50%;
  flex: 1;
  // position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 15px;
  .chat-item {
    border: 0 !important;
  }
  .chat-panel .chat-item {
    padding: 8px 0;
  }
}
.chat-panel {
  position: relative;
  // height:400px;
  height: calc(100vh - 355px);
  overflow-y: scroll;
  // padding:0 10px 30px;
  .chat-item {
    padding: 15px;
    display: flex;
    border-radius: 6px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    &.active {
      background-color: #20a8d8;
    }
    img {
      width: 40px;
      height: 40px;
      margin-right: 15px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    p {
      white-space: pre-wrap;
      background: #efefef;
      border-radius: 5px;
      padding: 8px;
      color: #333;
      position: relative;
      margin-bottom: 0px;
    }
    .chat-avatar {
      margin-top: 13px !important;
      margin-bottom: auto;
    }
  }
  .admin-message {
    text-align: right;
    flex-direction: row-reverse;
    //  margin:0 0 10px;
    border: 0;
    img {
      float: right;
      margin: 0 0 0 15px;
    }
    p {
      // background: #e4f5e8;
      background: rgba(32, 168, 216, 0.06);
      margin-bottom: 0px;
    }
  }
}
.chat-box {
  // background: #f5f5f5;
  position: relative;
  height: auto;
  z-index: 999;
  // padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 15px;
  button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: none;
    background-color: #3880ff;
    color: #fff;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button:disabled {
    opacity: 0.7;
  }
  textarea {
    height: 100%;
    border: 1px solid #ccc;
    margin-right: 10px;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    border-radius: 3px;
    width: 80%;
    outline: none;
    flex: 1;
  }
}
.chat-left {
  width: 300px;
  padding: 0 15px 0 0px;
  // position: relative;
  // float: left;
  .chat-panel {
    height: calc(100vh - 197px);
    // padding-top: 10px;
  }
  .chats {
    max-width: 70%;
    width: 100%;
  }
  .chat-item p {
    font-size: 13px;
    background: transparent !important;
    padding: 0;
    span {
      display: block;
      font-weight: 600;
    }
  }
  .chat-item:nth-child(2n) {
    text-align: left;
    flex-direction: row;
    img {
      float: right;
      margin: 0 15px 0 0;
    }
  }
}
@media (max-width: 780px) {
  .booking-panel .col-12.col-sm-6,
  .facilities-table .col-6,
  .static-content .col-12.col-sm-6 {
    margin: 0 auto;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .table-panel .table-top .input-group {
    max-width: 120px;
  }
  .table-panel .table td:last-child {
    display: table-cell;
    text-align: center;
  }
  .table-panel .table td:last-child .switch-label {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }
  .chat-section {
    max-width: 100%;
  }
}
@media (max-width: 680px) {
  .main .container-fluid {
    padding: 0 10px;
  }

  .booking-panel .card-header button {
    height: 34px;
  }
  .table-panel .table-top .input-group {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    display: block;
  }
  .col-6,
  .col-4,
  .col-5,
  .col-3,
  .col-2,
  .col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .booking-panel .card-header strong span {
    margin-left: 0;
    display: block;
    margin-top: 10px;
  }
  .static-content .card-body {
    flex: 1 1 auto;
    padding: 15px 0;
  }
  .facilities-table td:last-child button {
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  .image-upload {
    max-width: 48%;
    margin-right: 2%;
  }
  .add-new-button {
    margin-bottom: 20px !important;
  }
  .chat-left,
  .chat-right {
    width: 100%;
    position: relative;
    float: none;
    border: 0;
  }
  .chat-right {
    border-top: 1px solid #ccc;
  }
}

.add-credit-section .form-group {
  display: flex;
  margin-bottom: 0;
  margin-left: 20px;
  .form-control {
    height: 35px;
    border-radius: 5px 0 0 5px;
    max-width: 110px;
    --padding: 0 0 0 10px;
    font-size: 12px;
  }
  button {
    height: 34px;
    border-radius: 0 5px 5px 0;
  }
}
.new-credit-header {
  justify-content: flex-start;
}
.conference-form {
  position: relative;
  padding-right: 60px;
  .button-delete {
    position: absolute;
    right: 15px;
    top: 35px;
    z-index: 999;
    margin: 0;
    cursor: pointer;
    -webkit-appearance: none;
    border: 0;
    background: #f04141;
    color: #fff;
    appearance: none;
    padding: 0 10px;
  }
}
.holiday-panel {
  .repeat-section {
    padding-top: 35px;
  }
  .button-delete {
    position: relative;
    right: auto;
    top: auto;
    margin-top: 35px;
  }
}
.basic-settings-panel {
  .page-heading {
    margin: 20px 0 30px;
  }
}
.holidays-name {
  span {
    margin: 0 5px;
  }
  span:first-child {
    margin-left: 0 !important;
  }
}

.chat-image-preview {
  padding: 5px;
  span {
    position: relative;
    img {
      width: 40px;
    }
    i {
      position: absolute;
      cursor: pointer;
    }
  }
}

.select-image {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: none;
  background-color: #3880ff;
  color: #fff;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  input {
    visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.chat-image-content {
  width: 200px !important;
  height: 200px !important;
  margin-top: 5px !important;
}

.new-table tbody tr td {
  // line-height: 35px;
}
.new-table tbody tr td:last-child {
  border-left: 0;
}
.headingh3 {
  font-size: 20px;
  font-weight: 600;
  color: #0084f3;
}

.app-header .navbar-brand {
  width: 75px !important;
}

.DateInput_input {
  font-size: 15px !important;
  padding: 5px !important;
  text-align: center !important;
}
